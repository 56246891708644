export default {
  breakpoints: {
    xxs: `320px`,
    xs: `480px`,
    sm: `600px`,
    md: `768px`,
    lg: `992px`,
    xl: `1280px`,
  },
  color: {
    green: `#00f7bb`,
    black: `#252525`,
    white: `#ffffff`,
    get primary() {
      return this.white;
    },
    get body() {
      return this.white;
    }
  },
  fontSize: {
    sizes: [10, 12, 16, 20, 24, 32, 40, 48, 64, 96],
    get text() {
      return {
        sm: [ this.sizes[0], this.sizes[2], this.sizes[2], ],
        md: [ this.sizes[1], this.sizes[2], this.sizes[3], ],
        lg: [ this.sizes[2], this.sizes[3], this.sizes[4], ],
      }
    },
    get heading() {
      return {
        sm: [ this.sizes[3], this.sizes[4], this.sizes[5], ],
        md: [ this.sizes[5], this.sizes[6], this.sizes[7], ],
        lg: [ this.sizes[6], this.sizes[7], this.sizes[8], ],
      }
    },
  },
  fontFamily: {
    primary: `Sohne Light, sans-serif`,
    heading: `Sohne Light, sans-serif`,
    text: `Sohne Light, sans-serif`,
  },
  fontWeight: {
    light: 300,
    regular: 400,
    semiBold: 500,
    bold: 600,
  },
  lineHeight: {
    heading: 1.25,
    text: 1.5,
    button: 1,
  },
  transition: {
    duration: {
      short: 300,
      regular: 500,
      long: 600,
    },
    easing: `ease-in-out`,
  },
  gutters: {
    sm: 30,
    lg: 50,
    grid: 2,
  },
};
