export const EMPTY_STATE = 'EMPTY_STATE';
export const RESTORE_STATE = 'RESTORE_STATE';
export const UPDATE_BUSINESS_INFO = 'UPDATE_BUSINESS_INFO';
export const ENABLE_SERVICE = 'ENABLE_SERVICE';
export const DISABLE_SERVICE = 'DISABLE_SERVICE';
export const ENABLE_DATA_POINT = 'ENABLE_DATA_POINT';
export const DISABLE_DATA_POINT = 'DISABLE_DATA_POINT';
export const UPDATE_MODAL_ACTIVE = 'UPDATE_MODAL_ACTIVE';
export const UPDATE_MODAL_INPUTS = 'UPDATE_MODAL_INPUTS';
export const UPDATE_MODAL_DATA = 'UPDATE_MODAL_DATA';
export const UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE';
export const UPDATE_INTERSTITIAL_ACTIVE = 'UPDATE_INTERSTITIAL_ACTIVE';
export const UPDATE_INTERSTITIAL_PROGRESS = 'UPDATE_INTERSTITIAL_PROGRESS';

export const CREATE_CLIENT = 'CREATE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const CREATE_DISCOVERY_CALL = 'CREATE_DISCOVERY_CALL';
export const UPDATE_DISCOVERY_CALL = 'UPDATE_DISCOVERY_CALL';


export default {
  emptyState: EMPTY_STATE,
  restoreState: RESTORE_STATE,
  updateBusinessInfo: UPDATE_BUSINESS_INFO,
  enableService: ENABLE_SERVICE,
  disableService: DISABLE_SERVICE,
  updateModalActive: UPDATE_MODAL_ACTIVE,
  updateModalInputs: UPDATE_MODAL_INPUTS,
  updateModalData: UPDATE_MODAL_DATA,
  updateInputValue: UPDATE_INPUT_VALUE,
  updateInterstitialActive: UPDATE_INTERSTITIAL_ACTIVE,
  updateInterstitialProgress: UPDATE_INTERSTITIAL_PROGRESS,
  //createClient: CREATE_CLIENT,
  //updateClient: UPDATE_CLIENT,
  //createDiscoveryCall: CREATE_DISCOVERY_CALL,
  //updateDiscoveryCall: UPDATE_DISCOVERY_CALL
};
