import React from 'react';
import { Global, css } from '@emotion/core';

export default props => (
  <Global
    styles={reset}
  />
);

const reset = theme => css`
  *
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    font-family: ${theme.fonts.body || 'sans-serif'};
    color: ${theme.colors.body || '#ffffff'};
    background-color: ${theme.colors.body || '#f0f0f7'};
    box-sizing: border-box;
    overflow-y: scroll;
    height: auto;
    min-height: 100%;
    min-width: 320px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
  }

  body {
    margin: 0;
  }

  body,
  input,
  textarea,
  button,
  select {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-size-adjust: 100%;
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
  }

  ul {
    margin: 0;
    margin-left: 1.45rem;
    padding: 0;
    list-style-position: outside;
    list-style-image: none;
  }

  li *:last-child {
    margin-bottom: 0;
  }

  button {
    user-select: none;
  }

  [type="reset"],
  [type="submit"],
  html [type="button"] {
    -webkit-appearance: button;
  }

  input:focus {
    // outline: none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
  }

  input::-webkit-input-placeholder {
    color: inherit;
  }

  a {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  a:focus {
    color: inherit;
  }

  a:active,
  a:hover {
    outline-width: 0;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  img {
    border-style: none;
    max-width: 100%;
    margin: 0;
    font-style: italic;
    vertical-align: middle;
  }

  img[width],
  img[height] {
    max-width: none;
  }
`;
