import React from 'react';
import ReactModal from 'react-modal';

import Container from 'components/Container';
import InputsContent from 'components/Modal/InputsContent';
import ErrorContent from 'components/Modal/ErrorContent';

import { useStateContext, actions } from 'state';

export default props => {
  const [state, dispatch] = useStateContext();

  const closeModal = () => dispatch({
    type: actions.updateModalActive,
    active: false
  });

  return (
    <ReactModal
      isOpen={state.modal.active !== false}
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={modalStyles}
    >

      <Container p={[ 25, null, null, 50]} width={1}>

        {state.modal.active === 'inputs' &&
          <InputsContent closeModal={closeModal} />}

        {state.modal.active === 'error' &&
          <ErrorContent closeModal={closeModal} />}

      </Container>
    </ReactModal>
  );
};

const modalStyles = {
  overlay: {
    zIndex: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    maxWidth: '600px',
    left: '5%',
    right: '5%',
    margin: '0 auto',
    borderRadius: '0',
    padding: '0',
    top: '50%',
    bottom: 'initial',
    transform: 'translateY(-50%)',
    backgroundColor: '#000',
    display: 'flex'
  },
};
