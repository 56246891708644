import React, {useEffect, useState} from 'react';
import { Box } from '@rebass/grid/emotion';
import { ThemeProvider } from 'emotion-theming';

import Modal from 'components/Modal';
import Container from 'components/Container';

import { StateProvider, reducer, initialState } from 'state';

import Reset from 'css/reset';
import customStyles from 'src/styles/style.css';
import Fonts from 'css/fonts';
import { theme } from 'css/theme';
import Helmet from "react-helmet/es/Helmet";
import {checkEnv, getFetchParams, getNovaUrlBase, renderAvatar} from "../utilities/utilities";


const Layout = props => {

  let [currentUser, setCurrentUser] = useState({});
  let [validated, setValidated] = useState(false);
  let [currentPath, setCurrentPath] = useState('');
  let [currentPathBase, setCurrentPathBase] = useState('');
  let [subdomain, setSubdomain] = useState('');
  let [shouldShowLogin, setShouldShowLogin] = useState(false);
  let [homeLink, setHomeLink] = useState('');

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    if ((currentUser.id) || checkEnv() === 'localhost') {
      setValidated(true);
      setShouldShowLogin(false);
    } else {
      setTimeout(() => {
        setShouldShowLogin(true);
      }, 1000);
    }
  }, [currentUser]);

  useEffect(() => {
    const urlParts = window.location.host.split('.');
    const sub = urlParts.length ? urlParts[0] : null;

    setCurrentPath(window.location.pathname + window.location.search);
    setCurrentPathBase(window.location.pathname);
    setSubdomain(sub);

    setHomeLink(getNovaUrlBase());
  });

  const checkUser = () => {
    const url = getFetchParams().url;
    const query = `query { 
                          me {
                            id
                            name
                            avatar
                          }
                        }`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getFetchParams().token}`
      },
      body: JSON.stringify({
        query: query
      }),
    })
        .then(res => res.json())
        .then(res => setCurrentUser(res.data.me))
        .catch(err => console.log(err.message));
  };

  const renderLoginScreen = () => {
    let rootDomain;
    if ((checkEnv() === 'staging') || (checkEnv() === 'localhost')) {
      rootDomain = 'powerdigital.dev';
    } else if (checkEnv() === 'development') {
      rootDomain = 'pdmdev.co';
    } else if (checkEnv() === 'production') {
      rootDomain = 'powerdigital.io';
    }

    console.log('subdomain', subdomain);
    console.log('rootDomain', rootDomain);
    console.log('currentPath', currentPath);

    return (
        <div id="login-container" className="column is-3">
          <a href={`https://nova.${rootDomain}/login/google?redirect=https%3A%2F%2F${subdomain}.${rootDomain}${currentPath}`}>
            <div id="login-button" className="btn btn-default">
              LOGIN
            </div>
          </a>
        </div>
    )
  };

  const renderNavBarRight = () => {
    return (
        <div className="navbar-right">

          <div className="profile">
            {renderAvatar(currentUser)}
          </div>

          <div className="logout">
            <button onClick={handleLogout}>
              <img src="../img/power-button.svg" />
            </button>
          </div>

        </div>
    )
  };

  const getDomain = () => {
    const env = checkEnv();
    if (env === 'staging') {
      return '.powerdigital.dev'
    } else if (env === 'development') {
      return '.pdmdev.co'
    } else if (env === 'production') {
      return '.powerdigital.io'
    }
  };

  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${getDomain()}; path=/`;
    setValidated(false);
    setTimeout(() => {
      setShouldShowLogin(true);
    }, 500);
  };

  const handleLogout = () => {
    console.log('logging out current user');
    return deleteCookie('platform_api_token');
  };

  return (
        <StateProvider reducer={reducer} initialState={initialState}>
          <ThemeProvider theme={theme}>

            <Helmet>
              <title>Nova | Auditing Module</title>
              <link rel="stylesheet" type="text/css" href="../styles/style.css" />
              <link rel="stylesheet" href="https://use.typekit.net/jap2qib.css" />
            </Helmet>

            <customStyles />
            <Reset />
            <Fonts />

            <Container width={1} maxWidth='none' bg='#FFFFFF' p={20} position='relative'>
              <a href={`${homeLink}/`}>
                <Container
                    p={0}
                    mx={0}
                    ml={0}
                    mr='auto'
                    width={120}
                >
                  <img src="../img/Nova_v1.svg" style={{width: 120, height: 32}}/>
                </Container>
              </a>
              {(validated && currentPath !== '/' && currentPathBase !== '/results/') && renderNavBarRight()}
            </Container>

            <Main
                px={[25, null, null, 50]}
                py={[36, null, null, 72]}
                bg='#f0f0f7'
                color='#333'
            >

              {(!validated && currentPath !== '/' && currentPathBase !== '/results/' && shouldShowLogin) && renderLoginScreen()}

              {(validated || currentPath === '/' || currentPathBase === '/results/') &&
              React.Children.map(props.children, (child) => {
                return React.cloneElement(child, {
                  user: currentUser
                });
              })
              }

            </Main>

            <Modal />

          </ThemeProvider>
        </StateProvider>
  )
}

export default Layout;

const Main = Box.withComponent('main');
