import { getContrastColor } from 'js/utils';

// Scales
const breakpoints = [
  '480px',   // 0 - xs
  '600px',   // 1 - sm
  '768px',   // 2 - md
  '992px',   // 3 - lg
  '1280px',  // 4 - xl
];

const fontSizes = [
  10,  // 0
  12,  // 1
  16,  // 2 - base || body || copy
  20,  // 3
  24,  // 4 - subheading
  30,  // 5 - heading
  36,  // 6
  48,  // 7
  54,  // 8
  60,  // 9
];

const fontWeights = [
  100,  // 0 - thin
  200,  // 1 - extralight
  300,  // 2 - light
  400,  // 3 - normal || regular || book
  500,  // 4 - medium
  600,  // 5 - semibold
  700,  // 6 - bold
  800,  // 7 - extrabold
  900,  // 8 - black || heavy
];

const lineHeights = [
  1,      // solid
  1.125,  // tight || heading
  1.35,   // normal || body || copy
  1.5,    // loose
];

const letterSpacings = [
  '1px',  // md
  '3px',  // lg
  '5px',  // xl
];

const gutters = [
  15,  // sm
  30,  // md
  50,  // lg
];

const colors = {
  white: '#ffffff',
  silver: '#dddddd',
  gray: '#aaaaaa',
  black: '#111111',
  navy: '#001f3f',
  blue: '#0074d9',
  aqua: '#7fdbff',
  teal: '#39cccc',
  olive: '#3d9970',
  green: '#2ecc40',
  lime: '#01ff70',
  yellow: '#ffdc00',
  orange: '#ff851b',
  red: '#ff4136',
  fuchsia: '#f012be',
  purple: '#b10dc9',
  maroon: '#85144b',
};

const fonts = {
  //'Sofia Pro': `sofia-pro, sans-serif`,
  'Sohne Semi-Bold': 'Sohne Semi-Bold, sans-serif',
  'Sohne Bold': 'Sohne Bold, sans-serif',
  'Sohne Light': 'Sohne Light, sans-serif',
  //'Neue Einstellung': `"Neue Einstellung", sans-serif`,
  'Open Sans Pro': `"Source Sans Pro", sans-serif`,
};

const zIndices = {
  under: -1,
  even: 0,
  over: 1,
};


// Aliases
breakpoints.all = '0';
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

colors.copy = colors.white;
colors.primary = colors.white;

fonts.copy = fonts['Sohne Light'];

fontSizes.base = fontSizes[2];
fontSizes.body = fontSizes[2];
fontSizes.copy = fontSizes[2];
fontSizes.subheading = fontSizes[4];
fontSizes.heading = fontSizes[5];

fontWeights.thin = fontWeights[0];
fontWeights.extralight = fontWeights[1];
fontWeights.light = fontWeights[2];
fontWeights.normal = fontWeights[3];
fontWeights.regular = fontWeights[3];
fontWeights.book = fontWeights[3];
fontWeights.normal = fontWeights[3];
fontWeights.medium = fontWeights[4];
fontWeights.semibold = fontWeights[5];
fontWeights.bold = fontWeights[6];
fontWeights.extrabold = fontWeights[7];
fontWeights.black = fontWeights[8];
fontWeights.heavy = fontWeights[8];
fontWeights.body = fontWeights[3];
fontWeights.heading = fontWeights[5];

lineHeights.solid = lineHeights[0];
lineHeights.tight = lineHeights[1];
lineHeights.heading = lineHeights[1];
lineHeights.normal = lineHeights[2];
lineHeights.body = lineHeights[2];
lineHeights.copy = lineHeights[2];
lineHeights.loose = lineHeights[3];

letterSpacings.md = letterSpacings[0];
letterSpacings.lg = letterSpacings[1];
letterSpacings.xl = letterSpacings[2];

gutters.sm = gutters[0];
gutters.md = gutters[1];
gutters.lg = gutters[2];


// Variants
const textStyles= {
  body: {
    fontWeight: fontWeights.body,
    lineHeight: lineHeights.body,
    fontFamily: fonts.copy,
    color: colors.copy,
  },
  heading: {
    fontWeight: fontWeights.heading,
    lineHeight: lineHeights.heading,
    fontFamily: fonts.copy,
    color: colors.copy,
  },
  button: {
    fontWeight: fontWeights.semibold,
    lineHeight: lineHeights.solid,
    fontFamily: fonts.copy,
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
  },
};

const buttons = {
  primary: {
    color: getContrastColor(colors.primary),
    backgroundColor: colors.primary,

    background: 'rgb(90,40,35)',
    // eslint-disable-next-line
    background: 'linear-gradient(0deg, rgba(90,40,35,1) 0%, rgba(192,89,77,1) 100%)',
  },
  hover: {
    color: colors.white,
    backgroundColor: 'transparent',
    border: `1px solid ${colors.white}`,

    '&:hover': {
      color: getContrastColor(colors.primary),
      backgroundColor: colors.primary,
      borderColor: colors.primary,
    },
  },
  icon: {
    padding: 0,
    backgroundColor: 'transparent',
  },
};

const containerStyles = {
  max: {
    padding: { all: 3, md: 4, },
  },
};

export const theme = {
  breakpoints,
  colors,
  fonts,
  zIndices,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  gutters,
  buttons,
  textStyles,
  containerStyles,
};
