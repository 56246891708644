import styled from '@emotion/styled-base';
import { Flex as RebassFlex } from '@rebass/grid/emotion';
import { borders, width, height, size, positions, flexBasis } from 'css/style-props';


const Flex = styled(RebassFlex)`
  ${borders}
  ${height}
  ${width}
  ${size}
  ${positions}
  ${flexBasis}
`;


export default Flex;


Flex.defaultProps = {};

Flex.propTypes = {
  ...borders.propTypes,
  ...height.propTypes,
  ...width.propTypes,
  ...size.propTypes,
};
