import { actions } from 'state';

export const getAccountData = async ({ token, dispatch }) => {
  try {
    const urlBase = 'https://www.googleapis.com/analytics/v3';
    const options = { method: 'GET', headers: { 'Authorization': `Bearer ${token}` }};

    const accounts = await fetch(`${urlBase}/management/accounts`, options)
      .then(res => res.json())
      .then(({ items: accountData }) => accountData.map(({ id, name }) => ({ id, name })));

    const propertiesRequests = accounts.map(({ id: accountId }) => {
      return fetch(`${urlBase}/management/accounts/${accountId}/webproperties`, options)
        .then(res => res.json())
        .then(({ items: propertiesData }) => propertiesData.map(({ id, name, websiteUrl }) => ({ accountId, id, name, websiteUrl })));
    });

    const properties = await Promise.all(propertiesRequests)
      .then(data => data.reduce((properties, account) => [ ...properties, ...account ]), []);

    // const profilesRequests = properties.map(({ accountId, id: webPropertyId }) => {
    //   return fetch(`${urlBase}/management/accounts/${accountId}/webproperties/${webPropertyId}/profiles`, options)
    //     .then(res => res.json())
    //     .then(({ items: profilesData }) => profilesData.map(({ id, name }) => ({ accountId, webPropertyId, id, name })));
    // });

    // const profiles = await Promise.all(profilesRequests)
    //   .then(data => data.reduce((profiles, property) => [ ...profiles, ...property ]), []);


    dispatch({
      group: 'data',
      key: 'googleAnalytics',
      value: {
        accounts,
        properties,
        // profiles,
      },
      type: actions.updateBusinessInfo,
    });
  } catch (error) {
    console.log(`error`, error);

    return;
  }
};


export const bindWindowMessageEvent = ({ dispatch, state }) => () => {
  const handleWindowMessage = event => {
    if (event.data.source !== 'google-analytics-auth') return;

    const { access_token, refresh_token } = event.data.payload;

    dispatch({
      group: 'googleAnalytics',
      key: 'tokens',
      value: { access_token, refresh_token },
      type: actions.updateBusinessInfo,
    });
  };

  if (typeof window !== `undefined`) {
    window.addEventListener('message', handleWindowMessage, false);
  }
};
