import React from 'react';
import { Global, css } from '@emotion/core';

import NeueEinstellungNormal from 'fonts/NeueEinstellungNormal.woff';
import NeueEinstellungMedium from 'fonts/NeueEinstellungMedium.woff';
import NeueEinstellungRegular from 'fonts/NeueEinstellungRegular.woff';
import NeueEinstellungSemiBold from 'fonts/NeueEinstellungSemiBold.woff';
import NeueEinstellungBold from 'fonts/NeueEinstellungBold.woff';
import NeueEinstellungExtraBold from 'fonts/NeueEinstellungExtraBold.woff';
import NeueEinstellungBlack from 'fonts/NeueEinstellungBlack.woff';
import SohneSemiBold from 'fonts/soehne-web-halbfett.woff';
import SohneBold from 'fonts/soehne-web-kraftig.woff';
import SohneLight from 'fonts/soehne-web-leicht.woff';


export default props => (
  <Global
    styles={fonts}
  />
);

const fonts = theme => css`
  @font-face {
    font-family: 'Neue Einstellung';
    src: url('${NeueEinstellungNormal}') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Einstellung';
    src: url('${NeueEinstellungRegular}') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Einstellung';
    src: url('${NeueEinstellungMedium}') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Einstellung';
    src: url('${NeueEinstellungSemiBold}') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Einstellung';
    src: url('${NeueEinstellungBold}') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Einstellung';
    src: url('${NeueEinstellungExtraBold}') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Einstellung';
    src: url('${NeueEinstellungBlack}') format('woff');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Sohne Semi-Bold';
    src: url('${SohneSemiBold}') format('woff');
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Sohne Bold';
    src: url('${SohneBold}') format('woff');
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Sohne Light';
    src: url('${SohneLight}') format('woff');
    font-style: normal;
  }

  html {
    font-size: ${theme.fontSizes.base || 16}px;
  }
`;
