import {createSlice, ActionPayload} from "@reduxjs/toolkit";
import {
    buildClientByIdQuery,
    buildDiscoveryCallByIdQuery,
    buildDiscoveryCallMutation
} from "../../api/discovery-call";
import {formatQueryObject, getFetchParams} from "../../utilities/utilities";

/**
 * STATE
 */
const initialState = {
    client: {
        id: null,
        name: '',
        website: '',
        author: {},
        business_type: {},
        industry: {},
        lead_source: {},
        status: '',
        audits: [],
        contacts: []
    },
    discoveryCall: {
        id: null,
        status: "",
        score: null,
        audit: {},
        author: {},
        business_type: {},
        client: {},
        budgets: [],
        channels: [],
        competitors: [],
        customers: [],
        departments: [],
        goals: [],
        growths: [],
        products: [],
        questions: [],
        services: []
    },
    data: {
        clients: [],
        discoveries: [],
        departments: [],
        users: [],
    },
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    error: null
};

/**
 * Slice (state + reducer)
 */
export const slice = createSlice({
    name: "discoveries", // maps to the redux tree
    initialState,
    reducers: {

        /** Fetch all discoveries **/
        fetchDiscoveriesPending: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        fetchDiscoveriesFulfilled: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.data.discoveries = action.payload.discoveries;
        },
        fetchDiscoveriesRejected: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.err;
        },

        /** Fetch a single discovery call for viewing/editing **/
        fetchDiscoveryCallByIdPending: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        fetchDiscoveryCallByIdFulfilled: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.discoveryCall = action.payload.discoveryCall;
        },
        fetchDiscoveryCallByIdRejected: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.err;
        },

        /** Create a new discovery call record **/
        createDiscoveryCallPending: (state, action) => {
            state.isCreating = true;
            state.error = null;
        },
        createDiscoveryCallFulfilled: (state, action) => {
            state.isCreating = false;
            state.error = null;
            state.discoveryCall = action.payload.discoveryCall;
        },
        createDiscoveryCallRejected: (state, action) => {
            state.isCreating = false;
            state.error = action.payload.err;
        },

        /** Update the current discovery call **/
        updateDiscoveryCallPending: (state, action) => {
            state.isUpdating = true;
            state.error = null;
        },
        updateDiscoveryCallFulfilled: (state, action) => {
            state.isUpdating = false;
            state.error = null;
        },
        updateDiscoveryCallRejected: (state, action) => {
            state.isUpdating = false;
            state.error = action.payload.err;
        },

        /** Fetch all clients **/
        fetchClientsPending: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        fetchClientsFulfilled: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.data.clients = action.payload.clients;
        },
        fetchClientsRejected: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.err;
        },

        /** Fetch a client record **/
        fetchClientByIdPending: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        fetchClientByIdFulfilled: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.client = action.payload.client;
        },
        fetchClientByIdRejected: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.err;
        },

        /** Create a new client record **/
        createClientPending: (state, action) => {
            state.isCreating = true;
            state.error = null;
        },
        createClientFulfilled: (state, action) => {
            state.isCreating = false;
            state.error = null;
            state.client = action.payload.client;
        },
        createClientRejected: (state, action) => {
            state.isCreating = false;
            state.error = action.payload.err;
        },

        /** Update the current client record **/
        updateClientPending: (state, action) => {
            state.isUpdating = true;
            state.error = null;
        },
        updateClientFulfilled: (state, action) => {
            state.isUpdating = false;
            state.error = null;
        },
        updateClientRejected: (state, action) => {
            state.isUpdating = false;
            state.error = action.payload.err;
        },

        /** Fetch all departments **/
        fetchDepartmentsPending: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        fetchDepartmentsFulfilled: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.data.departments = action.payload.departments;
        },
        fetchDepartmentsRejected: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.err;
        },

        /** Fetch all users **/
        fetchUsersPending: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        fetchUsersFulfilled: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.data.users = action.payload.users;
        },
        fetchUsersRejected: (state, action) => {
            state.isLoading = false;
            state.error = action.payload.err;
        },

        /** Remove a discovery service **/
        removeDiscoveryServicePending: (state, action) => {
            state.isUpdating = true;
            state.error = null;
        },
        removeDiscoveryServiceFulfilled: (state, action) => {
            state.isUpdating = false;
            state.error = null;
        },
        removeDiscoveryServiceRejected: (state, action) => {
            state.isUpdating = false;
            state.error = action.payload.err;
        },

    }
});

const {
    fetchDiscoveriesPending,
    fetchDiscoveriesFulfilled,
    fetchDiscoveriesRejected,

    fetchDiscoveryCallByIdPending,
    fetchDiscoveryCallByIdFulfilled,
    fetchDiscoveryCallByIdRejected,

    createDiscoveryCallPending,
    createDiscoveryCallFulfilled,
    createDiscoveryCallRejected,

    updateDiscoveryCallPending,
    updateDiscoveryCallFulfilled,
    updateDiscoveryCallRejected,

    fetchClientsPending,
    fetchClientsFulfilled,
    fetchClientsRejected,

    fetchClientByIdPending,
    fetchClientByIdFulfilled,
    fetchClientByIdRejected,

    createClientPending,
    createClientFulfilled,
    createClientRejected,

    updateClientPending,
    updateClientFulfilled,
    updateClientRejected,

    fetchDepartmentsPending,
    fetchDepartmentsFulfilled,
    fetchDepartmentsRejected,

    fetchUsersPending,
    fetchUsersFulfilled,
    fetchUsersRejected,

    removeDiscoveryServicePending,
    removeDiscoveryServiceFulfilled,
    removeDiscoveryServiceRejected,

} = slice.actions;

/**
 * Actions / Thunks
 */
export const fetchDiscoveries = (/* filters, sort, pagination */) => async dispatch => {
    const url = getFetchParams().url;
    const query = `query { 
                          discoveries {
                            id
                            client {
                              id
                              name
                              status
                            }
                          }
                        }`;

    try {
        dispatch(fetchDiscoveriesPending());

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: query
            }),
        })
            .then(res => res.json())
            .catch(err => console.log(err.message));

        dispatch(fetchDiscoveriesFulfilled({ discoveries: response.data.discoveries /*, clients: response.data.clients */ }));
    } catch (err) {
        dispatch(fetchDiscoveriesRejected({err: 'error fetching discoveries'}));
    }
};

export const fetchDiscoveryCallById = (id) => async dispatch => {
    const url = getFetchParams().url;

    try {
        dispatch(fetchDiscoveryCallByIdPending());

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: buildDiscoveryCallByIdQuery(id)
            }),
        })
            .then(res => res.json())
            .catch(err => console.log(err.message));

        dispatch(fetchDiscoveryCallByIdFulfilled({ discoveryCall: response.data.discovery }));
    } catch (err) {
        dispatch(fetchDiscoveryCallByIdRejected({err: `error fetching discovery call for id ${id}`}));
    }

};

export const createNewDiscoveryCall = (clientId, businessTypeId) => async dispatch => {
    const url = getFetchParams().url;
    const query = `mutation discoveries {
                createDiscovery(client_id: ${clientId})
                {
                    id
                }
            }`;

    try {
        dispatch(createDiscoveryCallPending());

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: query
            }),
        })
            .then(res => res.json())
            .catch(err => console.log(err.message));

        const discoId = response.data.createDiscovery.id;

        await dispatch(createDiscoveryCallFulfilled({ discoveryCall: response.data.createDiscovery }));
        await dispatch(updateDiscoveryCall(
            'discoveries',
            'updateDiscovery',
            {id: discoId, business_type_id: businessTypeId},
            discoId
        ))

    } catch (err) {
        dispatch(createDiscoveryCallRejected({err: `error creating new discovery call`}));
    }
};

export const updateDiscoveryCall = (collection, mutation, data, discoId) => async dispatch => {
    const url = getFetchParams().url;

    try {
        dispatch(updateDiscoveryCallPending());

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: buildDiscoveryCallMutation(collection, mutation, data)
            }),
        })
            .then(res => res.json())
            .catch(err => console.log(err.message));

        const dataType = (Object.keys(response.data)[0]);

        await dispatch(updateDiscoveryCallFulfilled({ discoveryCall: response.data[dataType] }));
        await dispatch(fetchDiscoveryCallById(discoId));
    } catch (err) {
        dispatch(updateDiscoveryCallRejected({err: `error updating discovery call`}));
    }
};

export const removeDiscoveryService = (discoId, serviceId) => async dispatch => {
    const url = getFetchParams().url;
    const query = `
            mutation discovery_services {
                deleteDiscoveryService(discovery_id: ${discoId}, service_id: ${serviceId}) {
                    id
                }
            }
        `;

    try {
        dispatch(removeDiscoveryServicePending());

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: query
            }),
        })
            .then(res => res.json())
            .then(res => console.log(res))
            .catch(err => console.log(err.message));

        await dispatch(removeDiscoveryServiceFulfilled({ discoveryCall: response.data.deleteDiscoveryService }));
        await dispatch(fetchDiscoveryCallById(discoId));
    } catch (err) {
        dispatch(removeDiscoveryServiceRejected({err: `error removing discovery service`}));
    }
};



export const fetchAllClients = () => async dispatch => {
    const url = getFetchParams().url;
    const query = `query { 
                      clients {
                        data {
                          id
                          name
                          status
                          author {
                            id
                          }
                          audits {
                            id
                            discovery {
                              id
                            }
                          }
                        }
                      }
                    }`;

    try {
        dispatch(fetchClientsPending());

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: query
            }),
        })
            .then(res => res.json())
            .catch(err => console.log(err.message));

        dispatch(fetchClientsFulfilled({ clients: response.data.clients.data }));
    } catch (err) {
        dispatch(fetchClientsRejected({err: 'error fetching clients'}));
    }
};

export const fetchClientById = (clientId) => async dispatch => {
    const url = getFetchParams().url;

    try {
        dispatch(fetchClientByIdPending());

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: buildClientByIdQuery(clientId)
            }),
        })
            .then(res => res.json())
            .catch(err => console.log(err.message));

        dispatch(fetchClientByIdFulfilled({ client: response.data.client }));
    } catch (err) {
        dispatch(fetchClientByIdRejected({err: `error fetching client with id ${clientId}`}));
    }
};

export const createClient = (clientName) => async dispatch => {
    const url = getFetchParams().url;
    const query = `
            mutation clients {
                createClient(name: "${clientName}") {
                    id
                    name
                }
            }
        `;

    try {
        dispatch(createClientPending());

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: query
            }),
        })
            .then(res => res.json())
            .catch(err => console.log(err.message));

        await dispatch(createClientFulfilled({ client: response.data.createClient }));
    } catch (err) {
        dispatch(createClientRejected({err: `error creating new client record`}));
    }
};

export const updateClient = (data) => async dispatch => {
    const url = getFetchParams().url;
    console.log(formatQueryObject(data));
    const query = `
            mutation clients {
                updateClient(${formatQueryObject(data)}) {
                    id
                    name
                    website
                }
            }
        `;

    try {
        dispatch(updateClientPending());

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: query
            }),
        })
            .then(res => res.json())
            .catch(err => console.log(err.message));

        await dispatch(updateClientFulfilled({ client: response.data.updateClient }));
        await dispatch(fetchClientById(data.id));
    } catch (err) {
        dispatch(updateClientRejected({err: `error updating client record`}));
    }

};

export const fetchAllDepartments = () => async dispatch => {
    const url = getFetchParams().url;
    const query = `query { 
                      departments {
                        id
                        name
                        slug
                        services {
                          id
                          name
                          slug
                          is_active
                          is_current
                          producer
                          grade
                        }
                      }
                    }`;

    try {
        dispatch(fetchDepartmentsPending());

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: query
            }),
        })
            .then(res => res.json())
            .catch(err => console.log(err.message));

        dispatch(fetchDepartmentsFulfilled({ departments: response.data.departments }));
    } catch (err) {
        dispatch(fetchDepartmentsRejected({err: 'error fetching departments'}));
    }
};

export const fetchAllUsers = () => async dispatch => {
    const url = getFetchParams().url;
    const query = `query {
                      users {
                        data {
                          id
                          name
                          email
                          avatar
                        }
                      }
                    }`;

    try {
        dispatch(fetchUsersPending());

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getFetchParams().token}`
            },
            body: JSON.stringify({
                query: query
            }),
        })
            .then(res => res.json())
            .catch(err => console.log(err.message));

        dispatch(fetchUsersFulfilled({ users: response.data.users.data }));
    } catch (err) {
        dispatch(fetchUsersRejected({err: 'error fetching users'}));
    }
};

/**
 * Selectors
 */
export const selectDiscoveries = (state) => state.discovery.data.discoveries;
export const selectIsLoading = (state) => state.discovery.isLoading;
export const selectError = (state) => state.discovery.error;
export const selectCurrentDiscovery = (state) => state.discovery.discoveryCall;
export const selectCurrentClient = (state) => state.discovery.client;
export const selectDepartments = (state) => state.discovery.data.departments;
export const selectUsers = (state) => state.discovery.data.users;
export const selectClients = (state) => state.discovery.data.clients;

export default slice.reducer;
