import React from 'react';

import Box from 'components/Box';
import Flex from 'components/Flex';
import Heading from 'components/Heading';
import { BurntButton } from 'components/Link';
import DefaultInput from 'components/Modal/DefaultInput';
import GoogleAnalyticsInput from 'components/Modal/GoogleAnalyticsInput';

import { useStateContext, actions } from 'state';


export default props => {
  const { closeModal } = props;
  const [state, dispatch] = useStateContext();
  const { data, inputs: { confirm, ...inputs }} = state.modal;

  const updateInputValue = ({ dataPoint, input }) => ({ target: { value }}) => dispatch({
    dataPoint,
    input,
    value,
    type: actions.updateInputValue,
  });

  return (
    <>
      {Object.values(data).map(dataPoint => (
        <Flex
          key={dataPoint.handle}
          mb={4}
          width={1}
          flexDirection='column'
        >
          <Box width={1} mb={10}>
            <Heading>{dataPoint.title}</Heading>
          </Box>

          {Object.values(dataPoint.inputs).map((input, index) => {
            const sharedProps = {
              index,
              input,
              inputs,
              dataPoint,
              key: input.handle,
              inputValue: inputs[dataPoint.handle][input.handle],
              updateInputValue: updateInputValue({ dataPoint: dataPoint.handle, input: input.handle }),
            };

            switch (input.handle) {
              case 'google-analytics-auth':
                return <GoogleAnalyticsInput {...sharedProps} />

              default:
                return <DefaultInput {...sharedProps} />
            }
          })}
        </Flex>
      ))}

      <BurntButton
        css={modalConfirmStyles}
        onClick={() => {
          const { confirm, ...inputs } = state.modal.inputs;

          confirm({ inputs });
          closeModal();
        }}
      >
        Confirm
      </BurntButton>
    </>
  )
};

const modalConfirmStyles = {
  cursor: 'pointer',
  alignSelf: 'flex-end',
  marginLeft: 'auto',
};
