import tinycolor from 'tinycolor2';
import themeUtils from 'css/themeUtils';

export const makeRelativeUrl = (href = '#') =>
  href
    .replace('https://packdigital.com', '/')
    .replace(/^\/\//, '/');

export const handleize = string =>
  string
    .trim()
    .toLowerCase()
    .replace(/'/g, '')
    .replace(/[^\w\u00C0-\u024f]+/g, '-')
    .replace(/-{2,}/g, '-')
    .replace(/^-+|-+$/g, '');

export const encode = data =>
  Object
    .keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

export const getContrastColor = color =>
  (tinycolor(color).isDark())
    ? themeUtils.color.white
    : themeUtils.color.black

export const parseUrlParams = () => {
  if (typeof window !== `undefined`) {
    return window.location.search
      .slice(1)
      .split(';')
      .reduce((params, param) => ({ ...params, [param.split('=')[0]]: param.split('=')[1] }), {});
  }

  return {};
};

export default {
  makeRelativeUrl,
  handleize,
  encode,
  getContrastColor,
};
