import React from 'react';
import { Flex, Box } from '@rebass/grid/emotion';

import Label from 'components/Form/Label';


const TextBox = props => {
  const id = `text-${(Math.random() * 1000000000).toFixed()}`;
  return (
    <Flex
      {...props}
      flexWrap='wrap'
    >

      {props.label &&
        <Label
          htmlFor={id}
          width={1}
          mb={2}
          css={props.labelStyles}
        >
          {props.label}
        </Label>
      }

      <Box
        as='input'
        order={1}
        id={id}
        type={props.type}
        value={props.value}
        name={props.name}
        disabled={props.disabled || false}
        onChange={props.onChange}
        css={[textBoxStyles, props.inputStyles]}
       />

    </Flex>
  );
};

export default TextBox;

const textBoxStyles = {
  width: '100%',
  fontFamily: `Sohne Light, sans-serif`,
  fontWeight: 400,
  lineHeight: 1,
  border: 0,
  backgroundColor: '#f4f8ff',
  padding: 10,
  borderRadius: 5,
  color: '#333'
};
