import React from 'react';

import Box from 'components/Box';
import Flex from 'components/Flex';
import Text from 'components/Text';
import Heading from 'components/Heading';

import { useStateContext } from 'state';


export default props => {
  const { closeModal } = props;
  const [ state ] = useStateContext();

  return (
    <>
      <Flex mb={4} flexDirection='column' width={1}>
        <Box width={1} mb={10}>
          <Heading mb={2}>ERROR!</Heading>
          <Text style={{color: '#FFF'}}>{state.modal.data.error.message}</Text>
        </Box>
      </Flex>

      <button
        css={modalConfirmStyles}
        onClick={() => {
          closeModal();
        }}
      >
        <span role='img' aria-label='close'>Close</span>
      </button>
    </>
  );
};

const modalConfirmStyles = {
  cursor: 'pointer',
  backgroundColor: '#000',
  alignSelf: 'flex-end',
};
