import React from 'react';

import Box from 'components/Box';
import TextBox from 'components/Form/TextBox';


export default props => {
  const { index, input, inputValue, updateInputValue } = props;

  return (
    <Box key={input.handle}>
      <TextBox
        mt={(index === 0) ? 0 : 3}
        label={input.title}
        name={input.handle}
        value={inputValue}
        onChange={updateInputValue}
      />
    </Box>
  )
};
