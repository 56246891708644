import * as system from 'styled-system';
export * from 'styled-system';

const { compose, variant } = system;

export const bg = compose(
  system.backgroundImage,
  system.backgroundPosition,
  system.backgroundSize,
);

export const typography = compose(
  system.textStyle,
  system.fontFamily,
  system.fontSize,
  system.fontWeight,
  system.letterSpacing,
  system.textAlign,
  system.color,
);

export const positions = compose(
  system.position,
  system.bottom,
  system.left,
  system.right,
  system.top,
  system.zIndex,
);

// export const borders = compose(
//   system.border,
//   system.borderBottom,
//   system.borderColor,
//   system.borderLeft,
//   system.borderRadius,
//   system.borderRight,
//   system.borderStyle,
//   system.borderTop,
//   system.borderWidth,
// );

export const layout = compose(
  system.display,
  system.alignItems,
  system.alignSelf,
  system.flexBasis,
  system.flexWrap,
  system.justifyContent,
  system.maxWidth,
  system.order,
  system.height,
  system.width,
  system.size,
  system.space,
);

export const textStyle = variant({
  key: 'textStyles',
});

export const containerStyle = variant({
  key: 'containerStyles',
});
