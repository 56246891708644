export * from './actions';
export * from './StateContext';
export { default as actions } from './actions';
export { default as reducer } from './reducer';

export const initialState = {
  active: {
    businessType: '',
    dataPoints: {},
    services: [],
  },
  data: {
    businessTypes: [],
    services: [],
    dataPoints: [],
    googleAnalytics: {},
  },
  social: {
    facebook: '',
    instagram: '',
    twitter: '',
  },
  info: {
    name: '',
    // name: 'Can-a-Pet',
    url: 'http://',
    // url: 'http://canna-pet.com',
    logo: '',
    notes: '',
  },
  modal: {
    active: false,
    inputs: {},
    data: {},
  },
  interstitial: {
    active: false,
    progress: 0,
    message: '',
  },
  googleAnalytics: {
    tokens: {},
  },
  results: {},
  discoForm: {},

  currentClient: {
    id: null,
    name: '',
    website: '',
    status: '',
    business_type_id: null,
    industry_id: null,
    lead_source_id: null
  }

};
