import React, { useEffect } from 'react';
import Select from 'react-select';

import Label from 'components/Form/Label';
import Container from 'components/Container';
import { BurntButton } from 'components/Link';

import { useStateContext } from 'state';
import { bindWindowMessageEvent, getAccountData } from 'api/google-analytics';


export default props => {
  const { dataPoint, inputValue, updateInputValue } = props;
  const [state, dispatch] = useStateContext();

  useEffect(bindWindowMessageEvent({ state, dispatch }), []);

  useEffect(() => {
    const token = state.googleAnalytics.tokens.access_token;

    if (token) {
      getAccountData({ token, dispatch });
    }
  }, [ state.googleAnalytics.tokens.access_token, dispatch ])

  return (!state.googleAnalytics.tokens.access_token)
    ? (
      <BurntButton
        onClick={() => {
          fetch('/.netlify/functions/auth')
            .then(res => res.json())
            .then(({ url }) => {
              if (typeof window !== `undefined`) {
                window.open(url)
              }
            });
        }}
      >
        Auth with GA
      </BurntButton>
    )
    : (
      Object.entries(state.data.googleAnalytics)
        .map(([level, values], index, array) => {
          const idMap = {accounts: 'accountId', properties: 'webPropertyId', profiles: 'id'};
          const [lastLevel] = array[index - 1] || [];
          const lastValue = (inputValue) ? inputValue[lastLevel] : undefined;
          const isDisabled = index !== 0 && lastValue === undefined;
          const selectOptions = values
            .filter(item => item[idMap[lastLevel]] === lastValue)
            .map(({ id: value, name: label }) => ({ value, label }));
          const currentValue = (inputValue) ? selectOptions.find(option => option.value === inputValue[level]) : null;

          return (
            <Container key={`${dataPoint.handle}:${level}`}>
              <Label width={1}>{level}</Label>
              <Select
                css={{ width: '100%' }}
                name={level}
                options={selectOptions}
                styles={selectStyles}
                isDisabled={isDisabled}
                value={currentValue || null}
                onChange={event => {
                  // reset the values of all the selects below this one
                  const data = array.slice(index + 1).reduce((newData, [lowerLevel]) =>
                    ({ ...newData, [lowerLevel]: undefined }), {});

                  updateInputValue({
                    target: {
                      value: {
                        ...inputValue,
                        ...data,
                        [level]: event.value
                      }
                    }
                  });
                }}
              />
            </Container>
          )
      })
    )
};


const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #000',
    color: state.isSelected ? '#fff' : '#000',
    padding: 5,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  container: (provided, state) => ({
    ...provided,
    color: '#000',
    marginBottom: 20,
    opacity: (state.isDisabled) ? '0.3' : '1.0',
    pointerEvents: (state.isDisabled) ? 'none' : 'auto',
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
  }),
  input: (provided, state) => ({
    ...provided,
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
  }),
  menuPortal: (provided, state) => ({
    ...provided,
  }),
  placeholder: (provided, state) => ({
    ...provided,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
  }),
};
