import React from 'react';
import styled from '@emotion/styled-base';
import { Link as GatsbyLink } from 'gatsby';
import { Box } from '@rebass/grid/emotion';

import { makeRelativeUrl } from 'js/utils';

const Link = ({ to, activeClassName, ...props }) => {
  if (!to) {
    return (
      <button {...props}>
        {props.children}
      </button>
    )
  }

  const url = makeRelativeUrl(to);
  const internal = /^\/(?!\/)/.test(url);
  const newProps = {
    as: internal ? GatsbyLink : 'a',
    [internal ? 'to' : 'href']: url,
    activeClassName,
    ...props,
  };

  return (
    <Box {...newProps}>
      {props.children}
    </Box>
  );
};

Link.propTypes = {
  // to: PropTypes.string.isRequired,
};

export default Link;

export const BurntButton = styled(Link)`
  width: 200px;
  text-align: center;
  background: rgb(90,40,35);
  //background: linear-gradient(337.5deg, rgba(90, 40, 35, 1) 0%, rgba(192, 89, 77, 1) 100%);
  text-transform: uppercase;
  font-size: 14px;
  font-family: Sohne Light, sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 9px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.17);
  background-image: linear-gradient(109deg, #f36e5c 0%, #6272f5 100%);
`;
