import React from 'react';

import Text from 'components/Text';


const Label = props => (
  <Text
    {...props}
    as='label'
    htmlFor={props.htmlFor}
    css={{
      fontSize: 10,
      textTransform: 'uppercase',
        color: '#4a1fff',
        fontWeight: 700,
    }}
  >

    {props.children}

  </Text>
);

export default Label;
