import {
  initialState,
  EMPTY_STATE,
  RESTORE_STATE,
  UPDATE_BUSINESS_INFO,
  ENABLE_SERVICE,
  DISABLE_SERVICE,
  UPDATE_MODAL_ACTIVE,
  UPDATE_MODAL_INPUTS,
  UPDATE_MODAL_DATA,
  UPDATE_INPUT_VALUE,
  UPDATE_INTERSTITIAL_ACTIVE,
  UPDATE_INTERSTITIAL_PROGRESS,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  CREATE_DISCOVERY_CALL,
  UPDATE_DISCOVERY_CALL,
} from 'state';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_BUSINESS_INFO:
      return {
        ...state,
        [action.group]: {
          ...state[action.group],
          ...(action.key)
            ? { [action.key]: action.value }
            : action.value
        }
      };

    case ENABLE_SERVICE:
      return {
        ...state,
        active: {
          ...state.active,
          services: [ ...state.active.services, action.service.handle ]
        }
      };

    case DISABLE_SERVICE:
      return {
        ...state,
        active: {
          ...state.active,
          services: state.active.services.filter(handle => handle !== action.service.handle)
        }
      };

    case UPDATE_MODAL_ACTIVE:
      return {
        ...state,
        modal: {
          ...state.modal,
          active: action.active
        }
      };

    case UPDATE_MODAL_INPUTS:
      return {
        ...state,
        modal: {
          ...state.modal,
          inputs: { ...action.inputs },
        }
      };

    case UPDATE_MODAL_DATA:
      return {
        ...state,
        modal: {
          ...state.modal,
          data: { ...action.data },
        }
      };

    case UPDATE_INPUT_VALUE:
      return {
        ...state,
        modal: {
          ...state.modal,
          inputs: {
            ...state.modal.inputs,
            [action.dataPoint]: {
              ...state.modal.inputs[action.dataPoint],
              [action.input]: action.value
            }
          }
        }
      };

    case UPDATE_INTERSTITIAL_ACTIVE:
      return {
        ...state,
        interstitial: {
          ...state.interstitial,
          active: action.active
        }
      }

    case UPDATE_INTERSTITIAL_PROGRESS:
      return {
        ...state,
        interstitial: {
          ...state.interstitial,
          progress: action.progress || state.interstitial.progress,
          message: action.message || state.interstitial.message,
        }
      }

    case RESTORE_STATE:
      return {
        ...action.state,
        results: [
          ...action.results
        ]
      }

    case EMPTY_STATE:
      return {
        ...initialState,
      }

    case CREATE_CLIENT:
      console.log('reducer - creating new client record');
      return {

      };

    case UPDATE_CLIENT:
      console.log('reducer - updating current client record');
      return {
        ...state,
        client: {
          ...state.client,
          ...action.client
        }
      };

    case CREATE_DISCOVERY_CALL:
      console.log('creating new disco call');
      return {

      };

    case UPDATE_DISCOVERY_CALL:
      console.log('reducer - updating current disco call');
      return {
        ...state,
        discoForm: {
          ...state.discoForm,
          ...action.discoForm
        }
      };

    default:
      return state;
  }
};
