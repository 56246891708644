import PropTypes from 'prop-types';
import styled from '@emotion/styled-base';
import { Flex } from '@rebass/grid/emotion';
import { containerStyle, bg, positions, layout, borders } from 'css/style-props';


const Container = styled(Flex)`
  ${containerStyle}
  ${bg}
  ${positions}
  ${layout}
  ${borders}
`;

Container.defaultProps = {
  mx: 'auto',
  flexWrap: 'wrap',
  width: 1,
  maxWidth: 1220,
};

Container.propTypes = {
  as: PropTypes.string,
  variant: PropTypes.string,
  ...bg.propTypes,
  ...positions.proptypes,
  ...layout.propTypes,
};

export default Container;
