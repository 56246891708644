import React from "react";

/**
 * Converts a javascript object into a query that is graphql-friendly
 *
 * @param object
 * @returns {string}
 */
export const formatQueryObject = (object) => {
    return Object.keys(object).map(item => {
        if (typeof object[item] !== 'number') {
            return item + ':"' + object[item] + '"';
        } else {
            return item + ":" + object[item];
        }
    }).join(',');
};


/**
 * Checks if an input field is empty, used for form validation
 *
 * @param input
 * @returns {boolean}
 */
export const isEmptyInput = (input) => {
    return (!input || 0 === input.length || /^\s*$/.test(input));
};


/**
 * Flattens a nested javascript object down to one level with new key names
 *
 * for example, foo: {bar: 'bar'} would flatten down to foo.bar: 'bar'
 *
 * @param obj
 * @param opt_out
 * @param opt_paths
 * @returns {string}
 */
export const flattenObj = (obj, opt_out, opt_paths) => {
    let out = opt_out || {};
    let paths = opt_paths || [];
    return Object.getOwnPropertyNames(obj).reduce(function(out, key) {
        if (key === 'length' || obj[key] === null) {
            return out;
        }
        paths.push(key);
        if (typeof obj[key] === 'object') {
            flattenObj(obj[key], out, paths);
        } else {
            out[paths.join('.')] = obj[key];
        }
        paths.pop();
        return out;
    }, out)
};

export const getQuestionText = (questions, id) => {
    const filtered = questions.filter(q => {
        return q.id === id
    })[0];
    if (typeof filtered !== 'undefined') {
        return filtered.question;
    }
};


export const getAnswer = (questions, questionId) => {
    if (questions.length > 0) {
        const filtered = questions.filter(question => {
            return question.id === questionId
        })[0];

        if (typeof filtered !== 'undefined') {
            return filtered.answer;
        }
    }
};

export const getTopicNotes = (discoTopics, topicId) => {
    if (typeof discoTopics !== 'undefined') {
        const filtered = discoTopics.filter(topic => {
            return topic.id === topicId;
        });

        if (filtered.length) {
            return filtered[0].notes;
        }
    }
};

export const isBlankRegex = (str) => {
    return (/^\s*$/.test(str));
};

export const getCookie = (name) => {
    const re = new RegExp(name + "=([^;]+)");
    const value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
};

export const getFetchParams = () => {
    let url = 'https://platform.powerdigital.dev/api/v1/';
    let token;

    if (checkEnv() === 'localhost') {
        token = '4igtrbQOML7CupBqqQyra3nNpkKtBglug9yVfQ8eV2G6EsieFnsIivNorEut';
    }

    if (checkEnv() === 'staging') {
        token = getCookie('platform_api_token');
    }

    if (checkEnv() === 'development') {
        url = 'https://platform.pdmdev.co/api/v1/';
        token = getCookie('platform_api_token');
    }

    if (checkEnv() === 'production') {
        url = 'https://platform.powerdigital.io/api/v1/';
        token = getCookie('platform_api_token');
    }

    return {
        url: url,
        token: token
    }
};

export const checkEnv = () => {
    let domain = window.location.host.split('.');
    domain.shift();
    const rootDomain = domain.join('.');

    if (window.location.host === 'localhost:8000') {
        return 'localhost';
    } else if (rootDomain === 'pdmdev.co') {
        return 'development';
    } else if (rootDomain === 'powerdigital.dev') {
        return 'staging';
    } else if (rootDomain === 'powerdigital.io') {
        return 'production'
    }
};

export const getNovaUrlBase = () => {
    if (checkEnv() === 'localhost') {
        return '//localhost:3000';
    } else if (checkEnv() === 'development') {
        return 'https://nova.pdmdev.co';
    } else if (checkEnv() === 'staging') {
        return 'https://nova.powerdigital.dev';
    } else if (checkEnv() === 'production') {
        return 'https://nova.powerdigital.io';
    }
};

export const renderAvatar = (user) => {
    if (user.avatar === null) {
        return renderAvatarFallback(user.name);
    } else {
        return <img className="avatar" src={user.avatar} />;
    }
};

export const renderAvatarFallback = (name) => {
    const firstInitial = typeof name !== 'undefined' ? name.charAt(0).toUpperCase() : '';

    return <div className="avatar-fallback"><span>{firstInitial}</span></div>;
};