module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BlackBox","short_name":"BlackBox","start_url":"/","icon":"src/img/favicon-v2.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"46f3cff71cef140ae3b26d3ad2282150"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
