import styled from '@emotion/styled-base';
import Text from 'components/Text';


const Heading = styled(Text)`color: #FFF;`;

Heading.defaultProps = {
  as: 'h2',
  variant: 'heading',
};

Heading.propTypes = {
  ...Text.propTypes,
};

export default Heading;
