import {formatQueryObject, isBlankRegex} from "../utilities/utilities";
import {updateDiscoveryCall} from "../components/Discovery/discoveryCallSlice";

/**
 * Builds the graphql object needed to query a single discovery call by ID
 * @param {number} discoveryId
 * @returns {string}
 */
export const buildDiscoveryCallByIdQuery = (discoveryId) => {
    return `query {
        discovery(id: ${discoveryId}) {
            id
            status
            score
            audit {
              id
            }
            author {
              id
            }
            business_type {
              id
              slug
            }
            client {
              id
              name
              website
              status
              business_type {
                id
                slug
              }
              industry {
                id
                slug
              }
              lead_source {
                id
                slug
              }
              contacts {
                id
                name
                email
                title
                is_decision_maker
                notes
              }
            }
            budgets {
              id
              budget
              ad_spend
              notes
            }
            channels {
              id
              name
              percent
              notes
            }
            competitors {
              id
              name
              website
            }
            customers {
              id
              name
              industry
            }
            departments {
              id
              name
              slug
              notes
            }
            goals {
              id
              type
              metric
            }
            growths {
              id
              goal
            }
            products {
              id
              name
              cost
              margin
            }
            questions {
              id
              business_type {
                id
              }
              question
              topic {
                id
              }
              type
              fields
              answer
            }
            services {
              id
              name
              slug
              is_active
              is_current
              producer
              grade
              is_priority
              department {
                id
                slug
              }
            }
            topics {
              id
              notes
            }
        }
    }`
};


/**
 * Function for calling pretty much any mutation (i.e. createContact, updateContact, updateDiscoveryService, etc)
 *
 * NOTE: Should not be used for createClient, updateClient, createDiscovery, or updateDiscoveryQuestion because
 * each of those mutations have their own dedicated function
 *
 * @param {string} collection
 * @param {string} mutation
 * @param {object} data
 * @returns {string}
 */
export const buildDiscoveryCallMutation = (collection, mutation, data) => {
    return `
            mutation ${collection} {
                ${mutation}(${formatQueryObject(data)})
                {
                    id
                }
            }
        `
};


/**
 * Builds the graphql object needed to query a single client by ID
 * @param {number} clientId
 * @returns {string}
 */
export const buildClientByIdQuery = (clientId) => {
    return `query {
        client(id: ${clientId}) {
            id
            name
            website
            author {
                id
            }
            business_type {
                id
                slug
            }
            industry {
                id
                slug
            }
            lead_source {
                id
                slug
            }
            status
            audits {
                id
                discovery {
                    id
                }
            }
            contacts {
                id
            }
        }
    }`
};


/**
 * @param {number} questionId
 * @param {number} discoId
 * @param {Function} dispatch
 * @param {Function} formatter
 * @returns {function(...[*]=)}
 */
export const handleUpdateQuestion = (questionId, discoId, dispatch, formatter = (val) => {return val;}) => (event) => {
    const {value} = event.target;

    if (!isBlankRegex(value)) {
        dispatch(updateDiscoveryCall(
            'discovery_questions',
            'updateDiscoveryQuestion',
            {discovery_id: discoId, question_id: questionId, answer: formatter(value)},
            discoId
        ));
    }
};

/**
 * Same as handleUpdateQuestion but for Datepickers
 * Note: This only works if the Datepicker is for one of the discovery_questions
 * @param {number} questionId
 * @param {number} discoId
 * @param {string} date
 * @param {Function} dispatch
 */
export const handleUpdateDate = (questionId, discoId, date, dispatch) => {
    if (!isBlankRegex(date)) {
        dispatch(updateDiscoveryCall(
            'discovery_questions',
            'updateDiscoveryQuestion',
            {discovery_id: discoId, question_id: questionId, answer: date},
            discoId
        ));
    }
};


/**
 * Used for updating the notes field at the bottom of any topic page
 * @param {number} discoId
 * @param {number} topicId
 * @param {Function} dispatch
 * @returns {function(...[*]=)}
 */
export const handleUpdateTopicNotes = (discoId, topicId, dispatch) => (event) => {
    const {value} = event.target;

    if (!isBlankRegex(value)) {
        dispatch(updateDiscoveryCall(
            'discovery_topics',
            'updateDiscoveryTopic',
            {discovery_id: discoId, topic_id: topicId, notes: `""${value}""`},
            discoId
        ));
    }
};