import PropTypes from 'prop-types';
import styled from '@emotion/styled-base';
import { Box } from '@rebass/grid/emotion';
import { textStyle, space, typography, borders } from 'css/style-props';


const Text = styled(Box)`
  font-family: "Sohne Light", sans-serif;
  ${textStyle}
  ${space}
  ${typography}
  color: #333;
`;

Text.defaultProps = {
  as: 'p',
  variant: 'body',
};

Text.propTypes = {
  as: PropTypes.string,
  variant: PropTypes.string,
  ...space.propTypes,
  ...typography.propTypes,
  ...borders.propTypes,
};

export default Text;
