import styled from '@emotion/styled-base';
import { Box as RebassBox } from '@rebass/grid/emotion';
import { borders, width, height, size } from 'css/style-props';


const Box = styled(RebassBox)`
  ${borders}
  ${height}
  ${width}
  ${size}
`;


export default Box;


Box.defaultProps = {};

Box.propTypes = {
  ...borders.propTypes,
  ...height.propTypes,
  ...width.propTypes,
  ...size.propTypes,
};
